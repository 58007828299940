import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

import UserContext from "../../../Contexts/userContext";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent, Grid } from "@mui/material";

const rowHeaders = ["Members", "Edit"];

export default function TeamCard(props: {
  team: any;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMember: React.Dispatch<React.SetStateAction<any>>;
  setAction: React.Dispatch<React.SetStateAction<any>>;
}) {
  const { user } = React.useContext(UserContext);
  const cards = props.team
    ? [
        {
          key: "Name",
          value: props.team.display_name || props.team.id,
        },
        { key: "Status", value: props.team.status || "active" },
        {
          key: "Collections",
          value: String(
            props.team.collections.map((collection: string) => " " + collection)
          ),
          info: "To add or remove a collection from a team, please contact an admin",
        },
        {
          key: "# Members",
          value: props.team.members ? props.team.members.length : 0,
        },
        {
          key: "Date Created",
          value: props.team.date_created
            ? new Date(
                props.team.date_created._seconds * 1000
              ).toLocaleDateString("en-US")
            : "unknown",
        },
      ]
    : [];

  return (
    <React.Fragment>
      <Paper
        elevation={4}
        sx={{
          p: 2,
          backgroundColor: "secondary.dark",
          display: "flex",
          flexDirection: "column",
          height: "500px",
          overflow: "scroll",
        }}
      >
        <Typography variant="h6" pb={2} align="left" color={"white"}>
          Team Info
        </Typography>

        {!props.team && (
          <Typography
            fontStyle="italic"
            color="divider"
            variant="h5"
            pb={2}
            pt={10}
          >
            Select a Team to view details here
          </Typography>
        )}
        {props.team && (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={5} key={props.team.id + " overview"}>
                {cards.map((card: any) => (
                  <Tooltip
                    key={card.key}
                    title={card.info ? card.info : ""}
                    placement="top"
                    arrow
                  >
                    <Card
                      key={card.key}
                      sx={{
                        backgroundColor: "primary.light",
                        mb: 2,
                      }}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: "bold" }}
                          color="secondary.main"
                          gutterBottom
                        >
                          {card.key}
                        </Typography>
                        {props.team ? (
                          <Typography
                            variant="h6"
                            component="div"
                            color="primary.dark"
                          >
                            {card.value}
                          </Typography>
                        ) : (
                          <Skeleton variant="rounded" height={70} />
                        )}
                      </CardContent>
                    </Card>
                  </Tooltip>
                ))}
              </Grid>
              <Grid item xs={7} key={props.team.id + " members"}>
                <Card
                  sx={{
                    height: "100%",
                    maxHeight: "500px",
                    overflow: "scroll",
                  }}
                >
                  <CardContent>
                    <Table
                      size="small"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          {rowHeaders.map((rowHeader: string) => (
                            <TableCell
                              key={rowHeader}
                              align={rowHeader === "Edit" ? "center" : "left"}
                              sx={{ color: "secondary.main" }}
                            >
                              <b>{rowHeader}</b>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {props.team.members && props.team.members.length > 0 && (
                        <TableBody>
                          {props.team.members.map((row: any) => (
                            <TableRow key={row}>
                              <TableCell>{row}</TableCell>

                              <TableCell align="right" width={120}>
                                <Tooltip
                                  title={"Edit Process Assignments"}
                                  placement="left"
                                >
                                  <IconButton
                                    aria-label="editTeam"
                                    color="secondary"
                                    onClick={() => {
                                      props.setMember(row);
                                      props.setAction("edit");
                                      props.setModalOpen(true);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip
                                  title={"Remove Member"}
                                  placement="right"
                                >
                                  <IconButton
                                    disabled={user.email === row.email}
                                    aria-label="deleteTeam"
                                    color="secondary"
                                    onClick={() => {
                                      props.setMember(row);
                                      props.setAction("remove");
                                      props.setModalOpen(true);
                                    }}
                                  >
                                    <RemoveCircleOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                      {!props.team && (
                        <TableBody>
                          <TableRow>
                            {/* column for each header */}
                            {rowHeaders.map((c, ic) => (
                              <TableCell key={c + ic}>
                                {/* n = 10 rows */}
                                {[...Array(10)].map((r, ir) => (
                                  <Skeleton
                                    key={"column:" + ic + "row:" + ir}
                                    variant="text"
                                    sx={{ width: "100%", fontSize: "1rem" }}
                                  />
                                ))}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </CardContent>
                </Card>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  sx={{ width: "100%", backgroundColor: "white", mt: 2 }}
                  onClick={() => {
                    props.setAction("add");
                    props.setModalOpen(true);
                  }}
                >
                  Add Member
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </React.Fragment>
  );
}
