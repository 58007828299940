import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Footer from "../../Components/footer";
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const TabContent = [
  {
    q: "What applications are compatible?",
    a: "NextStep can be configured to work with almost all web-based applications and websites.",
  },
  {
    q: "What makes NextStep different from browser extensions that are used for creating training materials?",
    a: "Unlike other extensions that capture screenshots to create static training documents, NextStep processes are displayed directly on the applications where the action takes place - this enables users to 'learn by doing'.",
  },
  {
    q: "Does NextStep work on applications / websites for which we don't control the source code?",
    a: "Yes! NextStep works at the browser level and thus can be used on any website / application that is accessed via your browser.",
  },
  {
    q: "Can NextStep be used on any browser?",
    a: "Currently, NextStep is available on Google Chrome and Microsoft edge, with plans to expand to other browsers in the future",
  },
];

const TableCellCheck = () => (
  <TableCell align="center">
    <CheckCircleIcon
      sx={{
        height: "30px",
        width: "30px",
        color: "green",
      }}
    />
  </TableCell>
);

const TableCellX = () => (
  <TableCell align="center">
    <CancelIcon
      sx={{
        height: "30px",
        width: "30px",
        color: "gray",
      }}
    />
  </TableCell>
);

export default function AboutPage() {
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
        }}
      >
        <Container maxWidth={false} sx={{ mt: 4, mb: 4, width: "100%" }}>
          <Paper
            elevation={4}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
          >
            <Typography variant="h4" align="center" p={3}>
              About{" "}
              <Typography
                variant="inherit"
                component={"span"}
                color={"primary"}
              >
                NextStep
              </Typography>
            </Typography>
            <Typography variant="h6" align="center" p={3}>
              NextStep is a new approach to knowledge management that leverages
              the power of browser extensions to provide contextualized training
              and guides directly within the tools that you and your
              organization use.
            </Typography>
            <Divider
              variant="middle"
              sx={{ width: "50%", m: "auto", borderColor: "#ddd" }}
            />
            <br />
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "primary.light" }}>
                  <TableCell variant="head">Solution</TableCell>
                  <TableCell variant="head">
                    Powered by browser extension
                  </TableCell>
                  <TableCell variant="head">
                    Provides Dynamic Training
                  </TableCell>
                  <TableCell variant="head">
                    Works on any website or web-based appliation
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    borderWidth: "3px",
                    borderColor: "primary.main",
                    borderStyle: "solid",
                  }}
                >
                  <TableCell>
                    <Typography fontWeight={"bold"}>NextStep</Typography>
                  </TableCell>
                  <TableCellCheck />
                  <TableCellCheck />
                  <TableCellCheck />
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>
                      Extensions that create static training documentation (e.g.
                      screenshots, PDFs)
                    </Typography>
                  </TableCell>
                  {/* Powered by browser extension */}
                  <TableCellCheck />
                  {/* Provides Dynamic Training */}
                  <TableCellX />
                  {/* Works on any website or web-based appliation */}
                  <TableCellCheck />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}>
                      Step-by-step guides embedded within a product
                    </Typography>
                  </TableCell>
                  {/* Powered by browser extension */}
                  <TableCellX />
                  {/* Provides Dynamic Training */}
                  <TableCellCheck />
                  {/* Works on any website or web-based appliation */}
                  <TableCellX />
                </TableRow>
              </TableBody>
            </Table>
            <br />
            <Divider
              variant="middle"
              sx={{ width: "50%", m: "auto", borderColor: "#ddd" }}
            />

            <Typography variant="h5" align="center" p={3}>
              FAQs
            </Typography>
            <Box px={5}>
              {TabContent.map((item: any, index) => (
                <Accordion
                  key={item.q}
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>{item.q}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography align="left" ml={5} fontWeight={"bold"}>
                      {item.a}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Paper>
        </Container>
        <br />
        <Footer />
      </Box>
    </Box>
  );
}