import { getIdToken } from "firebase/auth";
import { UserOrgInfo } from "./getUserData";

export function LoadUserData(
  user: any = null,
  setModalInfo: (info: any) => null
) {
  return new Promise((resolve, reject) => {
    /* get JWT and pass to the API in the Authorization header */
    (async () => {
      const token = await getIdToken(user);

      /* used to retrive JWT for testing */
      console.log(token);

      UserOrgInfo(token)
        .then((result: any) => {
          resolve({ message: "login complete", orgInfo: result, token: token });
        })
        .catch((err) => {
          setModalInfo({
            message: "unable to load user information",
            open: true,
          });
          reject(err);
        });
    })();
  });
}
