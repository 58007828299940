import * as React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

function sequenceSummary(stepLog: any) {
  // this gives an object with dates as keys
  const groups = stepLog.reduce((groups: any[], entry: any) => {
    const sequence = entry.sequence;
    if (!groups[sequence]) {
      groups[sequence] = 0;
    }
    groups[sequence] += +1;
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((sequence) => {
    return {
      sequence,
      count: groups[sequence],
    };
  });

  return groupArrays;
}

export default function TopSequences(data: any) {
  const sequenceSummaryArray = sequenceSummary(data.stepHistory).sort(
    (a, b) => b.count - a.count
  );

  return (
    <React.Fragment>
      <Typography fontWeight="bold" color="primary.dark" variant="h5">
        Most Used Sequences
      </Typography>
      <br />
      {sequenceSummaryArray.length > 0 ? (
        <Stack>
          {sequenceSummaryArray.length > 0 && (
            <div>
              {" "}
              <Typography component="p" color="secondary" display="inline">
                <b>{sequenceSummaryArray[0].sequence}</b>
              </Typography>
              <Typography color="text.secondary" sx={{ flex: 1 }}>
                {sequenceSummaryArray[0].count} steps used
              </Typography>
            </div>
          )}
          {sequenceSummaryArray.length > 1 && (
            <div>
              {" "}
              <Typography component="p" color="secondary" display="inline">
                <b> {sequenceSummaryArray[1].sequence}</b>
              </Typography>
              <Typography color="text.secondary" sx={{ flex: 1 }}>
                {sequenceSummaryArray[1].count} steps used
              </Typography>
            </div>
          )}{" "}
          {sequenceSummaryArray.length > 2 && (
            <div>
              {" "}
              <Typography component="p" color="secondary" display="inline">
                <b> {sequenceSummaryArray[2].sequence}</b>
              </Typography>
              <Typography color="text.secondary" sx={{ flex: 1 }}>
                {sequenceSummaryArray[2].count} steps used
              </Typography>
            </div>
          )}{" "}
        </Stack>
      ) : (
        <Stack spacing={1}>
          <Skeleton variant="rounded" height={70} />
          <Skeleton variant="rounded" height={70} />
        </Stack>
      )}
      {/* <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link>
      </div> */}
    </React.Fragment>
  );
}
