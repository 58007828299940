import React, { useContext, useEffect } from "react";

import {
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { getSequenceInfo } from "../../../firebase/getUserData";
import DashboardContext from "../../../Contexts/dashboardContext";
import UserContext from "../../../Contexts/userContext";

import Sequence from "./sequence";
import { client } from "../../../Config/styling";

import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import { builder_step, sequenceGroup } from "./types";

export default function SequenceGroup(props: {
  token: string;
  seqGroups: sequenceGroup;
}) {
  const { orgSeqGroups } = useContext(DashboardContext);
  const { logout } = useContext(UserContext);

  const [seqGroupList, setSeqGroupList] = React.useState<any>(
    props.seqGroups || null
  );
  useEffect(() => {
    setSeqGroupList(props.seqGroups);
  }, [props.seqGroups]);

  const [stepArray, setStepArray] = React.useState<builder_step[]>([]);
  const [sequenceInfo, setSequenceInfo] = React.useState<any>({});

  /* group / sequence selection controls */
  const [seqGroup, setSeqGroup] = React.useState<any>("");
  const [seqGroupDocId, setSeqGroupDocId] = React.useState<string>("");
  const [selectedSequence, setSelectedSequence] = React.useState<any>(
    {} as any
  );

  const handleGroupChange = (event: SelectChangeEvent) => {
    const sequenceGroupDocId = Object.keys(
      orgSeqGroups.find(
        (group: any) => Object.values(group)[0] === event.target.value
      )
    )[0];

    setSeqGroupDocId(sequenceGroupDocId);
    setSeqGroup(event.target.value);
    setSelectedSequence("");
    setSequenceInfo({});
    setEditName(false);
  };

  /* when orgSeqGroups is updated, need to wipe seq and stepArray */
  useEffect(() => {
    setSeqGroup("");
    setSeqGroupDocId("");
    setSelectedSequence("");
    setSequenceInfo({});
    setStepArray([]);
  }, [orgSeqGroups, seqGroupList]);

  /* when a sequence is selected, call the endpoint to load up step details */
  const handleSeqChange = (event: SelectChangeEvent) => {
    setShowLoader(true);

    const sequence: any = event.target.value;
    setSelectedSequence(sequence);

    getSequenceInfo(props.token, seqGroupDocId, sequence.id, logout).then(
      (result: any) => {
        setSequenceInfo({
          description: result.description,
          initialUrl: result.initialUrl,
          name: result.name,
          id: result.id,
          disabled: result.disabled,
          status: result.status,
          chainedSequences: result.chainedSequences,
        });
        setStepArray(result.stepList);
      }
    );
  };

  const [groupSeqList, setGroupSeqList] = React.useState<any[]>([]);
  useEffect(() => {
    if (seqGroup && seqGroup.sequenceList) {
      setGroupSeqList(seqGroup.sequenceList);
    } else {
      setGroupSeqList([]);
    }
  }, [seqGroup]);

  const handleInputChange = (e: any) => {
    /* here we update the stepArray state without using setStepArray because we do not want a re-render on input changes */
    let property = e.target.id;

    setSequenceInfo({ ...sequenceInfo, [property]: e.target.value });
  };

  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  useEffect(() => {
    if (stepArray.length > 0) {
      setShowLoader(false);
    }
  }, [stepArray]);

  const [editName, setEditName] = React.useState<boolean>(false); // used to control the name change interface for the process

  return (
    <Paper
      elevation={4}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* <Stack direction={"column"}> */}
      <Stack direction={"row"} sx={{ display: "flex" }}>
        <FormControl fullWidth>
          <InputLabel id="sequenceGroup-select-label" color="secondary">
            Process Group
          </InputLabel>

          <Select
            MenuProps={client.Select_MenuProps}
            color="secondary"
            labelId="sequenceGroup-select"
            id="sequenceGroup-select"
            disabled={seqGroupList && seqGroupList.length > 0 ? false : true}
            label="Process Group"
            onChange={handleGroupChange}
            sx={{
              background:
                !seqGroupList || seqGroupList.length === 0
                  ? "primary.light"
                  : "#FFFFFF",
              margin: 1,
            }}
            value={seqGroupList && seqGroupList.length > 0 ? seqGroup : ""}
          >
            {seqGroupList
              .map((a: any) => Object.values(a)[0])
              .map((group: any) => (
                <MenuItem key={group.id} value={group}>
                  {group.displayName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Stack direction={"row"} width={"100%"}>
          {!editName && (
            <React.Fragment>
              <FormControl fullWidth>
                <InputLabel id="sequence-select-label" color="secondary">
                  Process
                </InputLabel>
                <Select
                  MenuProps={client.Select_MenuProps}
                  color="secondary"
                  disabled={seqGroup ? false : true}
                  labelId="sequence-select"
                  id="sequence-select"
                  value={selectedSequence}
                  label="Process"
                  onChange={handleSeqChange}
                  sx={{
                    background: !seqGroup ? "primary.light" : "#FFFFFF",
                    margin: 1,
                  }}
                >
                  {groupSeqList.map((seqItem: any) => (
                    <MenuItem
                      key={seqItem.id}
                      value={seqItem}
                      color={seqItem.disabled ? "divider" : "inherit"}
                      sx={{
                        fontStyle: seqItem.disabled ? "italic" : "normal",
                      }}
                    >
                      {seqItem.disabled
                        ? seqItem.name + " - disabled"
                        : seqItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Tooltip title={"Edit Process Name"} placement="top-end" arrow>
                {/* span to eliminate console errors */}
                <span>
                  <IconButton
                    size="small"
                    aria-label="editName"
                    sx={{ m: "auto", p: 2 }}
                    disabled={selectedSequence ? false : true}
                    onClick={() => {
                      setEditName(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Add Process"} placement="top-end" arrow>
                {/* span to eliminate console errors */}
                <span>
                  <IconButton
                    size="small"
                    aria-label="editName"
                    sx={{ m: "auto", p: 2 }}
                    disabled={seqGroup ? false : true}
                    onClick={() => {
                      setEditName(true);
                      setSelectedSequence("");
                      setSequenceInfo({});
                      let tempStepArray: builder_step[] = [];
                      for (let i = 0; i < 3; i++) {
                        let step = { fadeIn: false } as builder_step;
                        tempStepArray.push(step);
                      }
                      setStepArray(tempStepArray);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </React.Fragment>
          )}
          {editName && (
            <React.Fragment>
              <TextField
                disabled={seqGroup ? false : true}
                required
                fullWidth
                color="secondary"
                id="name"
                label="Process Name"
                value={sequenceInfo.name}
                type="search"
                variant="standard"
                multiline
                style={{
                  margin: 10,
                }}
                onChange={handleInputChange}
              />
              <Tooltip title={"Select Process"} placement="top-end" arrow>
                <IconButton
                  size="small"
                  aria-label="editName"
                  sx={{ m: "auto", p: 2 }}
                  onClick={() => {
                    setEditName(false);
                  }}
                >
                  <ListIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          )}
        </Stack>

        {showLoader && <LinearProgress />}
      </Stack>
      {(selectedSequence || sequenceInfo.name) && (
        <Sequence
          action={sequenceInfo.id ? "edit" : "add"}
          token={props.token}
          stepArray={stepArray}
          sequenceInfo={sequenceInfo}
          seqGroupDocId={seqGroupDocId}
          groupSequences={seqGroup.sequenceList}
        />
      )}
      {/* </Stack> */}
    </Paper>
  );
}
