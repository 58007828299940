import React, { useEffect } from "react";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import { client } from "../../../../Config/styling";

export default function CollectionItem(props: {
  collectionId: string;
  setCollectionId: React.Dispatch<string>;
  collectionOptions: any;
  setCollectionOptions: React.Dispatch<any>;
  isEdit: boolean;
}) {
  const [collectionOptions, setCollectionOptions] = React.useState<any>(
    props.collectionOptions
  );

  useEffect(() => {
    setCollectionOptions(props.collectionOptions);
  }, [props.collectionOptions]);

  return (
    <Box>
      {props.isEdit ? (
        <Box sx={{ minWidth: 120 }}>
          <TextField
            id="editTeam"
            color="secondary"
            label="Update Team display name"
            value={props.collectionOptions.display_name || ""}
            type="search"
            variant="standard"
            multiline
            fullWidth
            margin="dense"
            onChange={(event: any) => {
              let tempCollectionInfo = JSON.parse(
                JSON.stringify(collectionOptions)
              );

              tempCollectionInfo.display_name = event.target.value as string;
              props.setCollectionOptions(tempCollectionInfo);
            }}
          />
          <br />
          <br />
          <FormControl fullWidth>
            <InputLabel id="status-select-label" color="secondary">
              status
            </InputLabel>
            <Select
              inputProps={client.Select_MenuProps}
              color="secondary"
              labelId="status-select-label"
              id="status-select"
              // defaultValue="active"
              value={collectionOptions.status || "active"}
              label="Status"
              onChange={(event: SelectChangeEvent) => {
                let tempMemberInfo = JSON.parse(
                  JSON.stringify(collectionOptions)
                );

                tempMemberInfo.status = event.target.value as string;
                props.setCollectionOptions(tempMemberInfo);
              }}
            >
              <MenuItem value={"active"} color="secondary">
                Active
              </MenuItem>
              <MenuItem value={"inactive"}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </Box>
      ) : (
        <TextField
          color="secondary"
          id="newCollection"
          label="Enter Collection Name"
          value={props.collectionId}
          type="search"
          variant="standard"
          multiline
          fullWidth
          margin="dense"
          onChange={(event: any) => {
            props.setCollectionId(event.target.value);
          }}
        />
      )}

      <br />
    </Box>
  );
}
