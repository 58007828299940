export function UserOrgInfo(token: string) {
  return new Promise((resolve, reject) => {
    // Default options are marked with *
    fetch(
      `${process.env.REACT_APP_FUNCTION_BASE_URL}/getUserData?org=true`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // "Sec-Fetch-Mode": "cors",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          /* don't logout here beucase when this function is called, the user is not yet logged in and logout is not defined */
          resolve({});
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetUsageData(token: string, logout: () => null) {
  return new Promise((resolve, reject) => {
    // Default options are marked with *
    fetch(
      `${process.env.REACT_APP_FUNCTION_BASE_URL}/getUsageData`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // "Sec-Fetch-Mode": "cors",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          logout();
          resolve({});
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetOrgData(
  token: string,
  params: string[],
  logout: (involuntary: boolean) => null
) {
  return new Promise((resolve, reject) => {
    /* build a query param string */
    let queryParams = "?";

    params.forEach((element) => (queryParams += element + "=true&"));
    // Default options are marked with *
    fetch(
      `${process.env.REACT_APP_FUNCTION_BASE_URL}/getOrgData${queryParams}`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // "Sec-Fetch-Mode": "cors",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          logout(true);
          resolve({});
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function AddOrgItem(
  token: string,
  item: string,
  body: any,
  logout: (involuntary: boolean) => null
) {
  return new Promise((resolve, reject) => {
    // Default options are marked with *
    fetch(
      `${process.env.REACT_APP_FUNCTION_BASE_URL}/addOrgItem${item ? `?${item}=true` : ""}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // member: member,
          // "Sec-Fetch-Mode": "cors",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        if (response.status === 401) {
          logout(true);
          resolve({});
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function EditOrgItem(
  token: string,
  itemType: string,
  item: string,
  options: any,
  logout: (involuntary: boolean) => null
) {
  return new Promise((resolve, reject) => {
    // Default options are marked with *
    fetch(
      `${process.env.REACT_APP_FUNCTION_BASE_URL}/editRemoveOrgItem${itemType ? `?${itemType}=true` : ""}`,
      {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // member: member,
          // "Sec-Fetch-Mode": "cors",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ item: item, itemType: itemType, options }),
      }
    )
      .then((response) => {
        if (response.status === 401) {
          logout(true);
          resolve({});
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function RemoveOrgItem(
  token: string,
  itemType: string,
  item: string,
  logout: (involuntary: boolean) => null
) {
  return new Promise((resolve, reject) => {
    // Default options are marked with *
    fetch(
      `${process.env.REACT_APP_FUNCTION_BASE_URL}/editRemoveOrgItem`,
      {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // "Sec-Fetch-Mode": "cors",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ itemType: itemType, item: item }),
      }
    )
      .then((response) => {
        if (response.status === 401) {
          logout(true);
          resolve({});
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateTeamMembers(
  token: string,
  action: string,
  body: any,
  logout: (involuntary: boolean) => null
) {
  return new Promise((resolve, reject) => {
    // Default options are marked with *
    fetch(
      `${process.env.REACT_APP_FUNCTION_BASE_URL}/updateTeamMembers?action=${action}`, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // member: member,
        // "Sec-Fetch-Mode": "cors",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(body),
    }
    )
      .then((response) => {
        if (response.status === 401) {
          logout(true);
          resolve({});
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSequenceInfo(
  token: string,
  sequenceGroup: string,
  sequenceId: string,
  logout: (involuntary: boolean) => null
) {
  return new Promise((resolve, reject) => {
    // Default options are marked with *
    fetch(
      `${process.env.REACT_APP_FUNCTION_BASE_URL}/getSequenceInfo?sequenceGroup=` +
      sequenceGroup +
      "&sequenceId=" +
      sequenceId,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // "Sec-Fetch-Mode": "cors",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          logout(true);
          resolve({});
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function AddEditSequence(
  logout: (involuntary: boolean) => null,
  action: string,
  token: string,
  sequenceGroup: string,
  sequence: any,
  sequenceId: string
) {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_FUNCTION_BASE_URL}/addEditSequence?action=` +
      action +
      "&sequenceGroup=" +
      sequenceGroup +
      (action === "edit" ? "&sequenceId=" + sequenceId : ""), // only inclide sequenceId if this is an edit request

      {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // "Sec-Fetch-Mode": "cors",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ sequence: sequence }),
      }
    )
      .then((response) => {
        if (response.status === 401) {
          logout(true);
          resolve({});
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function AddEditSequeceGroup(
  token: string,
  action: string,
  body: any,
  logout: (involuntary: boolean) => null
) {
  return new Promise((resolve, reject) => {
    // Default options are marked with *
    fetch(
      `${process.env.REACT_APP_FUNCTION_BASE_URL}/addEditSequenceGroup?action=` +
      action,
      {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        if (response.status === 401) {
          logout(true);
          resolve({});
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
