import React, { useContext, useEffect } from "react";

import {
  Box,
  Typography,
  Checkbox,
  ListItemButton,
  ListItemIcon,
  FormControl,
  InputLabel,
  MenuItem,
  LinearProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import DashboardContext from "../../../../Contexts/dashboardContext";
import { ModalList, client } from "../../../../Config/styling";

export default function MemberItem(props: {
  memberId: string;
  setMemberId: React.Dispatch<string>;
  memberOptions: any;
  setMemberOptions: React.Dispatch<any>;
  isEdit: boolean;
}) {
  const { orgCollectionsActive, orgTeamsActive } = useContext(DashboardContext);

  /* update local state when props update */
  const [memberId, setMemberId] = React.useState<string>(props.memberId);

  const [memberOptions, setMemberOptions] = React.useState<any>(
    props.memberOptions
  );

  useEffect(() => {
    setMemberId(props.memberId);
  }, [props.memberId]);

  useEffect(() => {
    setMemberOptions(props.memberOptions);
  }, [props.memberOptions]);

  /* customized handleToggle that allows for certain permissions to automatically come with others */
  const handleToggle =
    (value: any, access_type: string, level: string) => () => {
      const newOptions = JSON.parse(JSON.stringify(props.memberOptions));
      const currentIndex =
        props.memberOptions[access_type][level].indexOf(value);

      if (currentIndex === -1) {
        /* ensure view access is toggled on appropriately when other permissions are checked */
        if (level !== "view") {
          if (props.memberOptions[access_type]["view"].indexOf(value) === -1) {
            newOptions[access_type]["view"].push(value);
          }
        }
        newOptions[access_type][level].push(value);
      } else {
        /* ensure other access is toggled off appropriately when view is unchecked */
        if (level === "view") {
          Object.keys(newOptions[access_type]).forEach((access) => {
            const accessIndex = newOptions[access_type][access].indexOf(value);
            if (accessIndex !== -1)
              newOptions[access_type][access].splice(accessIndex, 1);
          });
        }
      }
      props.setMemberOptions(newOptions);
    };

  return (
    <Box>
      {!props.isEdit && (
        <Box>
          <TextField
            id="newUser"
            color="secondary"
            label="Enter User's Email"
            value={memberId}
            type="search"
            variant="standard"
            multiline
            fullWidth
            margin="dense"
            onChange={(event: any) => {
              props.setMemberId(event.target.value);
            }}
          />
          <Typography
            id="new-member-type"
            variant="h6"
            color="secondary"
            gutterBottom
            sx={{ mt: 1 }}
          >
            {props.isEdit ? "Update" : "Choose"} account settings for{" "}
            <b>{memberId}</b>:
          </Typography>
        </Box>
      )}{" "}
      {/* member type */}
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="type-select-label" color="secondary">
            Type
          </InputLabel>
          <Select
            MenuProps={client.Select_MenuProps}
            color="secondary"
            labelId="type-select-label"
            id="type-select"
            // defaultValue="member"
            value={memberOptions.type || "member"}
            label="Type"
            onChange={(event: SelectChangeEvent) => {
              let tempItemOptions = JSON.parse(JSON.stringify(memberOptions));

              tempItemOptions.type = event.target.value as string;
              props.setMemberOptions(tempItemOptions);
            }}
          >
            <MenuItem value={"member"}>Member</MenuItem>
            <MenuItem value={"manager"}>Manager</MenuItem>
            <MenuItem value={"admin"}>Admin</MenuItem>
          </Select>
        </FormControl>

        <br />
        <br />
        {/* member type */}
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="status-select-label" color="secondary">
              status
            </InputLabel>
            <Select
              MenuProps={client.Select_MenuProps}
              color="secondary"
              labelId="status-select-label"
              id="status-select"
              // defaultValue="active"
              value={memberOptions.status || "active"}
              label="Status"
              onChange={(event: SelectChangeEvent) => {
                let tempMemberInfo = JSON.parse(JSON.stringify(memberOptions));

                tempMemberInfo.status = event.target.value as string;
                props.setMemberOptions(tempMemberInfo);
              }}
            >
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"inactive"}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {props.memberOptions.type === "manager" && (
          <Box>
            {/* collection access */}
            <Typography
              id="new-member-status"
              variant="h6"
              color="secondary"
              gutterBottom
              sx={{ mt: 1 }}
            >
              {props.isEdit ? "Update" : "Set"} collection permissions for{" "}
              <b>{memberId}</b>:
            </Typography>

            {orgCollectionsActive ? (
              <ModalList>
                {orgCollectionsActive.map((collection: any) => (
                  <li key={`section-${collection.id}`}>
                    <ul>
                      <ListItem
                        key={`item-${collection.id}`}
                        sx={{ padding: 0 }}
                        dense
                      >
                        <ListItemButton
                          role={undefined}
                          // onClick={handleToggle(collection.id)}
                          dense
                        >
                          <ListItemIcon>
                            <Tooltip title={"View"} placement="top">
                              <Checkbox
                                color="secondary"
                                onClick={handleToggle(
                                  collection.id,
                                  "collection_access",
                                  "view"
                                )}
                                id="collection_view"
                                edge="start"
                                checked={
                                  memberOptions.collection_access.view.indexOf(
                                    collection.id
                                  ) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": collection.id,
                                }}
                              />
                            </Tooltip>
                            <Tooltip title={"Edit"} placement="top">
                              <Checkbox
                                color="secondary"
                                onClick={handleToggle(
                                  collection.id,
                                  "collection_access",
                                  "edit"
                                )}
                                id="collection_edit"
                                edge="start"
                                checked={
                                  memberOptions.collection_access.edit.indexOf(
                                    collection.id
                                  ) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": collection.id,
                                }}
                              />
                            </Tooltip>
                          </ListItemIcon>
                          <ListItemText primary={collection.id} />
                        </ListItemButton>
                      </ListItem>
                    </ul>
                  </li>
                ))}
              </ModalList>
            ) : (
              <LinearProgress color="secondary" />
            )}

            {/* team access */}
            <Typography
              id="new-member-status"
              variant="h6"
              color="secondary"
              gutterBottom
              sx={{ mt: 1 }}
            >
              {props.isEdit ? "Update" : "Set"} team permissions for{" "}
              <b>{memberId}</b>:
            </Typography>
            {orgTeamsActive ? (
              <ModalList>
                {orgTeamsActive.map((team: any) => (
                  <li key={`section-${team.id}`}>
                    <ul>
                      <ListItem
                        key={`item-${team.id}`}
                        sx={{ padding: 0 }}
                        dense
                      >
                        <ListItemButton role={undefined} dense>
                          <ListItemIcon>
                            <Tooltip title={"View"} placement="top">
                              <Checkbox
                                color="secondary"
                                onClick={handleToggle(
                                  team.id,
                                  "team_access",
                                  "view"
                                )}
                                id="collection_view"
                                edge="start"
                                checked={
                                  memberOptions.team_access.view.indexOf(
                                    team.id
                                  ) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": team.id,
                                }}
                              />
                            </Tooltip>
                            <Tooltip title={"Edit Membership"} placement="top">
                              <Checkbox
                                color="secondary"
                                onClick={handleToggle(
                                  team.id,
                                  "team_access",
                                  "edit_membership"
                                )}
                                id="collection_edit"
                                edge="start"
                                checked={
                                  memberOptions.team_access.edit_membership.indexOf(
                                    team.id
                                  ) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": team.id,
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              title={"Edit Process Assignments"}
                              placement="top"
                            >
                              <Checkbox
                                color="secondary"
                                onClick={handleToggle(
                                  team.id,
                                  "team_access",
                                  "edit_seq_assigments"
                                )}
                                id="collection_edit"
                                edge="start"
                                checked={
                                  memberOptions.team_access.edit_seq_assigments.indexOf(
                                    team.id
                                  ) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": team.id,
                                }}
                              />
                            </Tooltip>
                          </ListItemIcon>
                          <ListItemText primary={team.id} />
                        </ListItemButton>
                      </ListItem>
                    </ul>
                  </li>
                ))}
              </ModalList>
            ) : (
              <LinearProgress color="secondary" />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
