import React, { useContext } from "react";

import {
  Modal,
  Box,
  Alert,
  Typography,
  Stack,
  Button,
  LinearProgress,
} from "@mui/material";

import { ModalStyle2 } from "../../../Components/modalStyle";

import {
  AddOrgItem,
  GetOrgData,
  AddEditSequeceGroup,
} from "../../../firebase/getUserData";
import DashboardContext from "../../../Contexts/dashboardContext";
import UserContext from "../../../Contexts/userContext";

import MemberItem from "./modalComponents/memberItem";
import TeamItem from "./modalComponents/teamItem";
import CollectionItem from "./modalComponents/collectionItem";
import SequenceGroupItem from "./modalComponents/sequenceGroupItem";

export default function AddItemModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  token: string;
  itemType: string;
  item: any;
  parentItem?: any;
}) {
  const { setOrgMembers, setOrgCollections, setOrgTeams, setOrgSeqGroups } =
    useContext(DashboardContext);
  const { logout } = useContext(UserContext);

  /* modal controls */
  const [modalMessage, setModalMessage] = React.useState<any>(null);

  const [itemOptions, setItemOptions] = React.useState<any>(props.item);
  const [itemId, setItemId] = React.useState<string>("");

  const handleSubmit = () => {
    setModalMessage(
      <Box sx={{ width: "100%" }}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {/* {props.info.message} */}
          Loading...
        </Typography>
        <br />
        <LinearProgress color="secondary" />
        <br />
      </Box>
    );

    /* handle custom cases for each item type at submission and validate */
    let errors = [] as string[];
    let submissionData: any = {};
    if (props.itemType === "member") {
      submissionData.user = itemId;
      submissionData.permissions = itemOptions;
    } else if (props.itemType === "team" || props.itemType === "collection") {
      submissionData.item = itemId;
      submissionData.options = itemOptions || {};
    } else if (props.itemType === "sequence-group") {
      if (itemOptions.displayName === "")
        errors.push("Process Group name is required");

      submissionData = itemOptions;
    }

    if (errors.length > 0) {
      setModalMessage(
        <Box sx={{ width: "100%" }}>
          <Alert severity={"error"}>{"ERROR"}</Alert>
          {errors.map((errorMessage: string) => (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {errorMessage}
            </Typography>
          ))}
          <br />
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              setModalMessage(false);
            }}
          >
            BACK
          </Button>
        </Box>
      );
    } else {
      const getData =
        props.itemType === "sequence-group"
          ? AddEditSequeceGroup(props.token, "add", submissionData, logout)
          : AddOrgItem(props.token, props.itemType, submissionData, logout);
      getData.then((response: any) => {
        /* happy path */
        if (response.result === "success") {
          setModalMessage(
            <Box sx={{ width: "100%" }}>
              <Alert severity={"success"}>{"SUCCESS"}</Alert>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {/* {props.info.message} */}
                {response.message}
              </Typography>
            </Box>
          );
          /* update the appropriate context so that changes are reflected */
          if (props.itemType === "member") {
            GetOrgData(props.token, ["members"], logout).then((result: any) => {
              setOrgMembers(result.members);
            });
          } else if (props.itemType === "team") {
            GetOrgData(props.token, ["teams"], logout).then((result: any) => {
              setOrgTeams(result.teams);
            });
          } else if (props.itemType === "collection") {
            GetOrgData(props.token, ["collections"], logout).then(
              (result: any) => {
                setOrgCollections(result.collections);
              }
            );
          } else if (props.itemType === "sequence-group") {
            GetOrgData(
              props.token,
              ["sequenceList", "collections"],
              logout
            ).then((result: any) => {
              setOrgSeqGroups(result.sequenceGroups);
              setOrgCollections(result.collections);
            });
          }

          setTimeout(() => {
            props.setOpen(false);
          }, 7000);

          /* unhappy path */
        } else {
          setModalMessage(
            <Box sx={{ width: "100%" }}>
              <Alert severity={"error"}>{"ERROR"}</Alert>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {/* {props.info.message} */}
                {response.message}
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                OKAY
              </Button>
            </Box>
          );
        }
      });
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>
        {!modalMessage ? (
          <Box>
            <Typography
              id="add-modal-description"
              variant="h6"
              color="secondary"
              gutterBottom
              sx={{ mt: 1 }}
            >
              {/* {props.info.message} */}
              Add a new{" "}
              {props.itemType === "sequence-group"
                ? "process group"
                : props.itemType}
              :
            </Typography>
            {props.itemType === "member" && (
              <MemberItem
                memberId={itemId}
                setMemberId={setItemId}
                memberOptions={itemOptions}
                setMemberOptions={setItemOptions}
                isEdit={false}
              />
            )}
            {props.itemType === "collection" && (
              <CollectionItem
                collectionId={itemId}
                setCollectionId={setItemId}
                collectionOptions={itemOptions}
                setCollectionOptions={setItemOptions}
                isEdit={false}
              />
            )}
            {props.itemType === "team" && (
              <TeamItem
                teamId={itemId}
                setTeamId={setItemId}
                teamOptions={itemOptions}
                setTeamOptions={setItemOptions}
                isEdit={false}
              />
            )}
            {props.itemType === "sequence-group" && (
              <SequenceGroupItem
                seqGroupId={itemId}
                setSeqGroupId={setItemId}
                seqGroupOptions={itemOptions}
                setSeqGroupOptions={setItemOptions}
                isEdit={false}
                collection={props.parentItem}
              />
            )}
            <br />
            <Stack direction="row" sx={{ bottom: 0 }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                sx={{ m: 1 }}
              >
                SUBMIT
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                sx={{ m: 1 }}
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                CANCEl
              </Button>
            </Stack>
          </Box>
        ) : (
          modalMessage
        )}
      </Box>
    </Modal>
  );
}
